<template>
  <div>
      <v-card>
        <v-card-title style="word-break: break-word;">
          <div class="text-overline">{{item.title}}</div>
        </v-card-title>
        <v-card-text style="word-break: break-word;">
          <div class="text-caption">{{item.description}}</div>
            <v-file-input
              :disabled="read_only"
              accept="image/*, application/pdf" id="curriculum_file" ref="curriculum_file"
              truncate-length="15"
              label="Selecione os arquivos"
              @change="handleChangeFile"
              show-size
              :rules="item.required ? [ ruleFile(), ruleSize() ] : []"
            ></v-file-input>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import validations from '@/mixins/validations';

export default {
  name: 'FileItem',
  mixins: [
    validations
  ],
  props: {
    item: {
      type: Object,
      default: {}
    },
    read_only: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    answer: null,
  }),
  methods: {
    handleChangeFile(nv){
      this.setItem(nv)
    },
    setItem(file){
      let new_done_item = {
        index_order: this.item.index_order,
        item_id: this.item.id,
        title: this.item.title,
        answer: this.answer,
        file: file,

      }
      let has_item_id = this.done_items_attributes.findIndex(done_item => {
        return done_item.item_id == this.item.id;
      }) < 0 ? false : true
      if(has_item_id){
        this.editInDoneItemsAttributes(new_done_item)
      }else{
        this.addToDoneItemsAttributes(new_done_item)
      }
    },
    ...mapActions({
      addToDoneItemsAttributes: 'DoneForm/addToDoneItemsAttributes',
      editInDoneItemsAttributes: 'DoneForm/editInDoneItemsAttributes'
    })
  },
  computed: {
    ...mapState({
      done_items_attributes: state=> state.DoneForm.done_items_attributes,
    })
  },
}
</script>

<style scoped>

</style>